<template>
	<div>
		<CRow>
			<CCol md="12">
				<CCard>
					<CCardHeader>
						<CRow>
							<CCol md="4">
								Dokumen Permohonan
							</CCol>
							<CCol md="8" class="text-right">
								
							</CCol>
						</CRow>
					</CCardHeader>
					<CCardBody>
						
					</CCardBody>
					<CCardFooter>
						<div class="text-right">
							<button class="btn btn-primary text-white btn-hover-outline-primary"><i class="fa fa-save"></i> Simpan</button>
						</div>
					</CCardFooter>
				</CCard>
			</CCol>
		</CRow>
	</div>
</template>
<script>
	const dataintable = [];
	export default {
		name: "AllDokumenPermohonan",
		components: {},
		data() {
			return {
				open_apli_value: '',
				popup_apli_value: '',
				popup_keterangan_value : '',
				open_apli : [],
				popup_apli : [],
				popup_keterangan : [],
				labelTxt: {
			        labelOn: 'yes',
			        labelOff: 'no'
			    }
			}
		},
		methods: {
		},
		beforeCreate() {
		},
	};
</script>